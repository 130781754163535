
import { StoreEntityType } from 'ngx-unificator/store';
import { PaymentAction, PaymentMethod, PaymentTabType } from '../vendor/payment-v3/platform-payment-types';

export interface PaymentStore {
  currency: string;
  paymentList: PaymentMethod[];
  selectedMethod: PaymentMethod;
  selectedMethodType: PaymentTabType;
  action: PaymentAction;
}

export const paymentStore: StoreEntityType<PaymentStore> = {
  currency: null,
  paymentList: [],
  action: null,
  selectedMethod: null,
  selectedMethodType: PaymentTabType.FIAT
};

export type PaymentStoreEntity = typeof paymentStore;
